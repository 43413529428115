<template>
  <div
    :class="`bet-history--${$route.params.game}`"
    class="wrap__contents bet-history--inner bet-history-update"
  >
    <div class="toolbar">
      <div
        class="toolbar__items searchbar"
        :class="[
          { 'sports': $route.params.game === 'sports' },
          { 'inplay': $route.params.game === 'inplay' },
          { 'bet365': $route.params.game === 'bet365' }
        ]"
      >
        <search
          :translations="translations"
          :date-by="dateBy"
          :filter-by="filterBy"
          @clear="clear"
          @trigger="search"
          @refresh="search"
          @payload="filterByKeywords"
        />
      </div>

      <div
        v-if="checkReadWrite"
        class="toolbar__items button"
      >
        <button
          v-if="['sports'].includes($route.params.game)"
          class="btn--modify"
          @click="modifyMatch('bulk')"
        >
          <i class="ico--modify"></i>
          <span>{{ translate(translations, 'sel modify') }}</span>
        </button>
      </div>
    </div>

    <div class="wrap__inner">
      <check-data-list
        v-if="(isDataLoading || isEmpty)"
        :is-loading="isDataLoading"
        :is-empty="isEmpty"
      />

      <template v-else>
        <tbl
          v-if="$route.params.game === 'casino'"
          ref="tbl"
          :data-loading="isDataLoading"
        >
          <template slot="head">
            <tbl-td
              v-if="['sports'].includes($route.params.game)"
              name="checkbox"
            >
              <checkbox>
                <input
                  :checked="selectAll"
                  type="checkbox"
                  @click="checkAll(matchList)"
                >
              </checkbox>
            </tbl-td>

            <tbl-td
              name="provider"
              v-text="translate(translations, 'provider')"
            />

            <tbl-td
              name="name"
              v-text="translate(translations, 'name')"
            />

            <tbl-td
              name="bet amount"
              v-text="translate(translations, 'bet amount')"
            />

            <tbl-td
              name="bet count"
              v-text="translate(translations, 'bet count')"
            />
          </template>

          <template slot="body">
            <tbl-row
              v-for="(match, index) in matchList"
              :key="index"
              @click.native="open({ gameID: match.id, prdID: match.prd_id })"
            >
              <tbl-td
                v-if="['sports'].includes($route.params.game)"
                name="checkbox"
                @click.native.stop
              >
                <checkbox>
                  <input
                    v-model="selected"
                    :value="match.id"
                    type="checkbox"
                  >
                </checkbox>
              </tbl-td>

              <tbl-td
                name="provider_name"
                v-text="translate(translations, match.provider_name)"
              />

              <tbl-td
                name="name"
                v-text="lang === 'en' ? match.name : match.kor"
              />

              <tbl-td name="total_bet_amount">
                {{ match.total_bet_amount | formatAmount }}
              </tbl-td>

              <tbl-td
                name="bet_count"
                v-text="match.total"
              />
            </tbl-row>
          </template>
        </tbl>

        <tbl
          v-else-if="$route.params.game === 'token'"
          ref="tbl"
          :data-loading="isDataLoading"
        >
          <template slot="head">
            <tbl-td
              sort
              name="play_date"
              :text="translate(translations, 'play_date')"
              @click.native="sort('play_date')"
            />

            <tbl-td
              sort
              name="name"
              :text="translate(translations, 'game')"
              @click.native="sort('game')"
            />

            <tbl-td
              sort
              name="round"
              :text="translate(translations, 'round')"
              @click.native="sort('round')"
            />

            <tbl-td
              sort
              name="home"
              :text="translate(translations, 'home')"
              @click.native="sort('home')"
            />

            <tbl-td
              sort
              name="tie"
              :text="translate(translations, 'tie')"
              @click.native="sort('tie')"
            />

            <tbl-td
              sort
              name="away"
              :text="translate(translations, 'away')"
              @click.native="sort('away')"
            />

            <tbl-td
              sort
              name="bet-count"
              @click.native="sort('bet_count')"
              v-text="translate(translations, 'bet count')"
            />
          </template>

          <template slot="body">
            <tbl-row
              v-for="(match, index) in matchList"
              :key="index"
              @click.native="open({
                game: $route.params.game,
                gameID: match.gt_id,
                prdID: match.prd_id,
                roundID: match.round_id
              })"
            >
              <tbl-td
                name="play_date"
                :text="match.play_date"
              />

              <tbl-td
                name="name"
                :text="lang === 'en' ? match.name : match.kor"
              />

              <tbl-td
                name="round"
                :text="match.round_id"
              />

              <tbl-td name="home">
                <template slot="inner">
                  <div
                    class="table__tr"
                  >
                    <div class="table__td team-name">
                      <div class="team-name__inner capitalize">
                        <span>{{ match.data.home ? match.data.home.bet_type : '-' }}</span>
                      </div>
                    </div>

                    <div class="table__td team-bets">
                      <span>{{ match.data.home && match.data.home.total_bet_amount | FormatAmount('whole') }}</span>
                    </div>

                    <div class="table__td team-odds">
                      <span>{{ match.data.home && match.data.home.odds || 0 }}</span>
                    </div>
                  </div>
                </template>
              </tbl-td>

              <tbl-td name="tie">
                <template slot="inner">
                  <div class="table__tr">
                    <div class="table__td team-bets">
                      <span>{{ match.data.tie && match.data.tie.total_bet_amount | FormatAmount('whole') }}</span>
                    </div>

                    <div class="table__td team-odds">
                      {{ match.data.tie ? match.data.tie.odds : '-' }}
                    </div>
                  </div>
                </template>
              </tbl-td>

              <tbl-td name="away">
                <template slot="inner">
                  <div class="table__tr">
                    <div class="table__td team-odds">
                      <span>{{ match.data.away && match.data.away.odds || 0 }}</span>
                    </div>

                    <div class="table__td team-bets">
                      <span>{{ match.data.away && match.data.away.total_bet_amount | FormatAmount('whole') }}</span>
                    </div>

                    <div class="table__td team-name capitalize">
                      <div class="team-name__inner">
                        <span>{{ match.data.away ? match.data.away.bet_type : '-' }}</span>
                      </div>
                    </div>
                  </div>
                </template>
              </tbl-td>

              <tbl-td
                name="bet_count"
                :text="match.data.home.total"
              />
            </tbl-row>
          </template>
        </tbl>

        <tbl
          v-else-if="$route.params.game === 'bet365'"
          ref="tbl"
          :data-loading="isDataLoading"
        >
          <template slot="head">
            <tbl-td
              sort
              name="play_date"
              :text="translate(translations, 'play_date')"
              @click.native="sort('play_date')"
            />

            <tbl-td
              sort
              name="name"
              :text="translate(translations, 'game')"
              @click.native="sort(lang === 'en' ? 'game' : 'game_kor')"
            />

            <tbl-td
              sort
              name="sports"
              :text="translate(translations, 'sports')"
              @click.native="sort('sports')"
            />

            <tbl-td
              sort
              name="stadium"
              :text="translate(translations, 'stadium')"
              @click.native="sort('stadium')"
            />

            <tbl-td
              name="total-bet"
              :text="translate(translations, 'total bet')"
              sort
              @click.native="sort('total_bet_amount')"
            />

            <tbl-td
              sort
              name="home"
              :text="translate(translations, 'home_team')"
              @click.native="sort(lang === 'en' ? 'home_team' : 'home_team_kor')"
            />

            <tbl-td
              sort
              name="away"
              :text="translate(translations, 'away_team')"
              @click.native="sort(lang === 'en' ? 'away_team' : 'away_team_kor')"
            />

            <tbl-td
              sort
              name="bet count"
              :text="translate(translations, 'bet count')"
              @click.native="sort('bet_count')"
            />
          </template>

          <template slot="body">
            <!-- @click.native="handleOpenBetHistory({ gameID: match.table_id })" -->
            <tbl-row
              v-for="(match, index) in matchList"
              :key="index"
              @click.native="open({
                game: $route.params.game,
                gameID: match.table_id,
                matchID: match.match_id
              })"
            >
              <tbl-td
                name="play_date"
                :text="match.play_date"
              />

              <tbl-td
                name="name"
                :text="translateData(match, 'name', 'kor')"
              />

              <tbl-td
                name="sports"
                :text="match.sports"
              />

              <tbl-td
                name="stadium"
                :text="match.stadium"
              />

              <tbl-td
                name="total bet"
                :text="match.total_bet_amount | FormatAmount('whole')"
              />

              <tbl-td name="home">
                <template slot="inner">
                  <div
                    class="table__tr"
                    @click="handleOpenBetHistory({ game: match.info.game, selected: match.info.Home.name, info: match.info.Home })"
                  >
                    <div class="table__td team-name">
                      <div class="team-name__inner">
                        <span v-html="translateData(match.home_team, 'en', 'kr') || '-'"></span>
                      </div>
                    </div>

                    <div class="table__td team-bets">
                      <span>10000</span>
                    </div>

                    <div class="table__td team-odds">
                      <span>1.5</span>
                    </div>
                  </div>
                </template>
              </tbl-td>

              <tbl-td name="away">
                <template slot="inner">
                  <div class="table__tr">
                    <div class="table__td team-odds">
                      <span>1.5</span>
                    </div>

                    <div class="table__td team-bets">
                      <span>10000000</span>
                    </div>

                    <div class="table__td team-name">
                      <div class="team-name__inner">
                        <span v-html="translateData(match.away_team, 'en', 'kr') || '-'"></span>
                      </div>
                    </div>
                  </div>
                </template>
              </tbl-td>

              <tbl-td name="bet_count">
                {{ match.total }}
              </tbl-td>
            </tbl-row>
          </template>
        </tbl>

        <tbl
          v-else
          ref="tbl"
          :data-loading="isDataLoading"
        >
          <template slot="head">
            <tbl-td
              v-if="['sports'].includes($route.params.game)"
              name="checkbox"
            >
              <checkbox>
                <input
                  :checked="selectAll"
                  type="checkbox"
                  @click="checkAll(matchList)"
                >
              </checkbox>
            </tbl-td>

            <tbl-td
              v-if="$route.params.game !=='mini'"
              name="active"
            >
              {{ translate(translations, 'betting status') }}
            </tbl-td>

            <tbl-td name="play-date">
              {{ translate(translations, 'play date') }}
            </tbl-td>

            <tbl-td name="type">
              {{ translate(translations, 'type') }}
            </tbl-td>

            <tbl-td name="game">
              {{ translate(translations, 'game') }}
            </tbl-td>

            <tbl-td name="league">
              {{ translate(translations, 'league') }}
            </tbl-td>

            <tbl-td name="lost-exception">
              {{ translate(translations, 'lost exception') }}
            </tbl-td>

            <tbl-td name="home">
              {{ translate(translations, 'home') }}
            </tbl-td>

            <tbl-td name="tie">
              {{ translate(translations, 'tie') }}
            </tbl-td>

            <tbl-td name="away ">
              {{ translate(translations, 'away') }}
            </tbl-td>

            <tbl-td name="bet-count">
              {{ translate(translations, 'bet count') }}
            </tbl-td>
          </template>

          <template slot="body">
            <tbl-row
              v-for="(match, index) in matchList"
              :key="index"
            >
              <tbl-td
                v-if="['sports'].includes($route.params.game)"
                name="checkbox"
                @click.native.stop
              >
                <checkbox>
                  <input
                    v-model="selected"
                    :value="match.id"
                    type="checkbox"
                  >
                </checkbox>
              </tbl-td>

              <tbl-td
                v-if="$route.params.game !== 'mini'"
                name="active"
              >
                <select2
                  v-if="checkReadWrite"
                  ref="match-game-active"
                  :key="match.id"
                  v-model="match.match_game_active"
                  @input="checkSameMatches($event, match.id, index)"
                >
                  <option2
                    key="active"
                    :value="true"
                    :selected="match.match_game_active"
                  >
                    {{ translate(translations, 'active') }}
                  </option2>

                  <option2
                    key="inactive"
                    :value="false"
                    :selected="!match.match_game_active"
                  >
                    {{ translate(translations, 'inactive') }}
                  </option2>
                </select2>

                <template v-else>
                  {{ match.match_game_active ? translate(translations, 'active') : translate(translations, 'inactive') }}
                </template>
              </tbl-td>

              <tbl-td name="play-date">
                <template v-if="match.match_play_date">
                  {{ match.match_play_date | date('MM-DD HH:mm') }}
                </template>

                <template v-else>
                  -
                </template>
              </tbl-td>

              <tbl-td name="type">
                <template v-if="language === 'en'">
                  {{ match.type_eng }}
                </template>
                <template v-if="language === 'kr'">
                  {{ match.type_kor ? match.type_kor : match.type_eng }}
                </template>
              </tbl-td>

              <tbl-td name="game">
                <img
                  v-if="match.game_img"
                  :src="basePath + match.game_img"
                >
                <template v-if="language === 'en'">
                  {{ match.game_eng }}
                </template>
                <template v-if="language === 'kr'">
                  {{ match.game_kor ? match.game_kor : match.game_eng }}
                </template>
              </tbl-td>

              <tbl-td
                name="league"
                :class="{ 'new-league': $route.params.game ==='mini' }"
              >
                <img
                  v-if="match.league_img"
                  :src="basePath + match.league_img"
                >

                <template v-if="language === 'en'">
                  <span
                    v-html="match.league_eng"
                  >
                  </span>
                </template>

                <template v-if="language === 'kr'">
                  <span
                    v-html="match.league_kor || match.league_eng"
                  >
                  </span>
                </template>

                <span
                  v-if="$route.params.game ==='mini'"
                  class="bet-highlight"
                >
                  {{ match.round }} {{ translate(translations,'round') }}
                </span>
              </tbl-td>

              <tbl-td name="lost-exception">
                <span class="lost">
                  ({{ match.bet_amount_red | formatAmount('whole') }})
                </span>
                <span class="win">
                  {{ match.bet_amount_green | formatAmount('whole') }}
                </span>
              </tbl-td>

              <tbl-td name="home">
                <template slot="inner">
                  <div
                    class="table__tr"
                    @click="handleOpenBetHistory({ game: match.info.game, selected: match.info.Home.name, info: match.info.Home })"
                  >
                    <div class="table__td team-name">
                      <div class="team-name__inner">
                        <template v-if="language === 'en'">
                          <span v-html="match.home_eng"></span>

                          <!-- <span
                            v-if="$route.params.game ==='mini'"
                            class="bet-highlight"
                          >
                            ({{ match.round }} {{ translate(translations,'round') }})
                          </span> -->
                        </template>

                        <template v-if="language === 'kr'">
                          <span v-html="match.home_kor ? match.home_kor : match.home_eng"></span>

                          <!-- <span
                            v-if="$route.params.game ==='mini'"
                            class="bet-highlight"
                          >
                            ({{ match.round }} {{ translate(translations,'round') }})
                          </span> -->
                        </template>

                        <span
                          v-if="$route.params.game === 'inplay'"
                          class="bet-highlight"
                        >
                          ({{ match.info.Home.name }})
                        </span>

                        <i
                          v-if="match.type_eng.indexOf('Handicap') !== -1"
                          class="ico--handicap"
                        >
                        </i>

                        <i
                          v-if="match.type_eng.indexOf('Over/Under') !== -1"
                          class="ico--over-under"
                          :class="`is-${match.info.Home.name.toLowerCase()}`"
                        >
                        </i>
                      </div>
                    </div>

                    <div class="table__td team-bets">
                      <span>{{ match.info.Home.amount | formatAmount('whole') }}</span>
                    </div>

                    <div class="table__td team-odds">
                      <span>{{ match.info.Home.odd }}</span>
                    </div>
                  </div>
                </template>
              </tbl-td>

              <tbl-td name="tie">
                <template slot="inner">
                  <div
                    class="table__tr"
                    @click="handleOpenBetHistory({game: match.info.game, selected: match.info.Draw.name, info: match.info.Draw})"
                  >
                    <div class="table__td team-bets">
                      <span
                        v-if="match.info.Draw.name.length > 0
                          && match.match_game_type !== 'handicap'
                          && match.match_game_type !== 'over/under'"
                      >
                        {{ match.info.Draw.amount | formatAmount('whole') }}
                      </span>
                    </div>

                    <div class="table__td team-odds">
                      <span v-if="match.info.game.key !== 'odd'">{{ match.info.game.key }}</span>
                      <span v-if="match.info.game.key === 'odd'">{{ match.info.Draw.odd }}</span>
                    </div>
                  </div>
                </template>
              </tbl-td>

              <tbl-td name="away">
                <template slot="inner">
                  <div
                    class="table__tr"
                    @click="handleOpenBetHistory({game: match.info.game, selected: match.info.Away.name, info: match.info.Away})"
                  >
                    <div class="table__td team-odds">
                      <span>{{ match.info.Away.odd }}</span>
                    </div>

                    <div class="table__td team-bets">
                      <span>{{ match.info.Away.amount | formatAmount('whole') }}</span>
                    </div>

                    <div class="table__td team-name">
                      <i
                        v-if="match.type_eng.indexOf('Handicap') !== -1"
                        class="ico--handicap"
                      >
                      </i>

                      <i
                        v-if="match.type_eng.indexOf('Over/Under') !== -1"
                        class="ico--over-under"
                        :class="`is-${match.info.Away.name.toLowerCase()}`"
                      >
                      </i>

                      <div class="team-name__inner">
                        <span
                          v-if="$route.params.game === 'inplay'"
                          class="bet-highlight"
                        >
                          ({{ match.info.Away.name }})
                        </span>

                        <template v-if="language === 'en'">
                          <!-- <span
                            v-if="$route.params.game ==='mini'"
                            class="bet-highlight"
                          >
                            ({{ match.round }} {{ translate(translations,'round') }})
                          </span> -->

                          <span v-html="match.away_eng"></span>
                        </template>

                        <template v-if="language === 'kr'">
                          <!-- <span
                            v-if="$route.params.game ==='mini'"
                            class="bet-highlight"
                          >
                            ({{ match.round }} {{ translate(translations,'round') }})
                          </span> -->

                          <span v-html="match.away_kor ? match.away_kor : match.away_eng"></span>
                        </template>
                      </div>
                    </div>
                  </div>
                </template>
              </tbl-td>

              <tbl-td name="bet-count">
                <span v-if="$route.params.game !=='mini'">
                  {{ match.info.Home.betCount+match.info.Away.betCount+match.info.Draw.betCount }}
                </span>
                <span v-if="$route.params.game ==='mini'">
                  {{ match.bet_count }}
                </span>
              </tbl-td>
            </tbl-row>
          </template>
        </tbl>
      </template>
    </div>

    <modal
      v-if="showBetHistory"
      :draggable="true"
      :activate="showBetHistory"
      class="modal--create-user"
      @activate="showBetHistory = $event"
    >
      <template slot="body">
        <bet-list-new />
      </template>

      <template slot="footer">
      </template>
    </modal>

    <modal
      v-if="showBetModify"
      :draggable="true"
      :title="translate(translations, 'modify')"
      :activate="showBetModify"
      class="modal--modify-bet"
      @activate="showBetModify = $event"
    >
      <template slot="body">
        <div class="form">
          <div class="form__body">
            <div class="form__item">
              <div class="form__label">
                {{ translate(translations, 'goalserve') }}
              </div>

              <div class="form__data">
                <checkbox
                  :toggle-a="translate(translations, 'hold all')"
                  :toggle-b="translate(translations, 'unhold all')"
                  type="toggle"
                  class="toggle--inverted"
                >
                  <input
                    v-model="editMatch.isChange"
                    type="checkbox"
                  >
                </checkbox>
              </div>
            </div>

            <div class="form__item">
              <div class="form__label">
                {{ translate(translations, 'type') }}
              </div>

              <div class="form__data-inner">
                <span
                  v-if="language === 'en'"
                  v-html="editMatch.type_eng"
                >
                </span>

                <span
                  v-if="language === 'kr'"
                  v-html="editMatch.type_kor ? editMatch.type_kor : editMatch.type_eng"
                >
                </span>
              </div>
            </div>

            <div class="form__item">
              <div class="form__label">
                {{ translate(translations, 'play date') }}
              </div>

              <div class="form__data">
                <date-time-picker
                  ref="datetime"
                  v-model="editMatch.match_play_date"
                  mode="from"
                  type="datetime"
                />
              </div>
            </div>

            <div class="form__item form__item--bettables">
              <div class="form__label">
                {{ translate(translations, 'home') }}
              </div>

              <div class="form__data">
                <div class="form__data-inner -label">
                  <span
                    v-if="language === 'en'"
                    v-html="editMatch.home_eng"
                  >
                  </span>

                  <span
                    v-if="language === 'kr'"
                    v-html="editMatch.home_kor ? editMatch.home_kor : editMatch.home_eng"
                  >
                  </span>
                </div>

                <div
                  v-if="!editMatch.isAuto"
                  class="form__data-inner -data"
                >
                  {{ editMatch.info.Home.odd }}
                </div>

                <div
                  v-else
                  class="input-wrap"
                >
                  <input
                    v-model="editMatch.info.Home.odd"
                    type="text"
                  >
                </div>
              </div>
            </div>

            <div class="form__item">
              <div class="form__label">
                {{ translate(translations, 'draw') }}
              </div>

              <div class="form__data">
                <div
                  v-if="!editMatch.isAuto"
                  class="form__data-inner"
                >
                  {{ editMatch.info.Draw.odd }}
                </div>

                <div
                  v-else
                  class="input-wrap"
                >
                  <input
                    v-model="editMatch.info.Draw.odd"
                    type="text"
                  >
                </div>
              </div>
            </div>

            <div class="form__item form__item--bettables">
              <div class="form__label">
                {{ translate(translations, 'away') }}
              </div>

              <div class="form__data">
                <div class="form__data-inner -label">
                  <span
                    v-if="language === 'en'"
                    v-html="editMatch.away_eng"
                  >
                  </span>

                  <span
                    v-if="language === 'kr'"
                    v-html="editMatch.away_kor ? editMatch.away_kor : editMatch.away_eng"
                  >
                  </span>
                </div>

                <div
                  v-if="!editMatch.isAuto"
                  class="form__data-inner -data"
                >
                  {{ editMatch.info.Away.odd }}
                </div>

                <div
                  v-else
                  class="input-wrap"
                >
                  <input
                    v-model="editMatch.info.Away.odd"
                    type="text"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>

      <template slot="footer">
        <button
          v-if="checkReadWrite"
          class="btn--modify"
          @click="modifyMatch('single')"
        >
          <i class="ico--save"></i>
          <span>
            {{ translate(translations, 'confirm') }}
          </span>
        </button>

        <button
          v-if="checkReadWrite"
          class="btn--delete"
          @click="showBetModify = false"
        >
          <i class="ico--close"></i>
          <span>
            {{ translate(translations, 'cancel') }}
          </span>
        </button>
      </template>
    </modal>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

//= components
import Search from '@/components/base/search/Search'
import CheckDataList from '@/components/base/CheckDataList'
import Modal from '@/components/base/Modal'
import Checkbox from '@/components/base/Checkbox'
import DateTimePicker from '@/components/base/DateTimePicker'
import BetListNew from '@/views/bet-history/BetListNew'

//= mixins
import siteDefaults from '@/assets/js/mixins/base/SiteDefaults'
import { getStyles } from '@/assets/js/mixins/base/GetStyles'
import { getListStatus } from '@/assets/js/mixins/common/GetListStatus'
import { checkAll } from '@/assets/js/mixins/base/CheckAll'
import { vueLocalStorage } from '@/assets/js/mixins/base/VueLocalStorage'

// External Libs
import _isEqual from 'lodash/isEqual'
import _isEmpty from 'lodash/isEmpty'
import _startCase from 'lodash/startCase'
import _pickBy from 'lodash/pickBy'

//= translation
import { translations } from '@/assets/js/translations/Betslip'

import FormatAmount from '@/assets/js/filters/FormatAmount'
const generate = (display, value) => ({ display: _startCase(display), value: value || display })

export default {
  name: 'BetHistoryNew',

  components: {
    Search,
    CheckDataList,
    BetListNew,
    Modal,
    Checkbox,
    DateTimePicker
  },

  filters: {
    FormatAmount,

    total (amounts) {
      return amounts && amounts.length
        ? amounts.reduce((e = 0, a = 0) => Number(e) + Number(a))
        : 0
    },

    teams (item, lang) {
      const homeTeam = this.translateData(item, 'home_team_name', 'home_team_name_kor')
      const awayTeam = this.translateData(item, 'away_team_name', 'home_team_name_kor')

      return `${homeTeam} vs ${awayTeam}`
    },

    totalBets (bets, key) {
      let _b = null
      if (key === 'home') {
        _b = bets[0]
        if (bets.length === 3) {
          _b = bets.find(e => {
            console.log(e, ' && !e.isTestUser')
            return Object.keys(e)[0].toLowerCase() === 'home'
          })
        }
      }
      if (key === 'away') {
        _b = bets[1]
        if (bets.length === 3) {
          _b = bets.find(e => {
            return Object.keys(e)[0].toLowerCase() === 'away'
          })
        }
      }
      if (key === 'tie') {
        if (bets.length === 3) {
          _b = bets.find(e => {
            return Object.keys(e)[0].toLowerCase() === 'draw'
          })
        }
      }

      if (bets.length === 1 && key === 'home') {
        const firstKey = Object.keys(_b)[0]
        const betAmts = _b[firstKey].map(e => { return e.bet_amount })
        return betAmts.reduce((a, e) => {
          return a + e
        }, 0)
      }

      if (bets.length === 1 && key === 'away') {
        return 0
      }

      if (_b === null) {
        return
      }
      const _k = Object.keys(_b)[0]
      const betAmts = _b[_k].filter(e => {
        return !e.isCancel && !e.isTestUser
      }).map(e => { return e.bet_amount })
      return betAmts.reduce((a, e) => {
        return a + e
      }, 0)
    },

    betArea (bets, key) {
      let _b = null
      if (key === 'home') {
        _b = bets[0]
        if (bets.length === 3) {
          _b = bets.find(e => {
            return Object.keys(e)[0].toLowerCase() === 'home'
          })
        }
      }
      if (key === 'away') {
        _b = bets[1]
        if (bets.length === 3) {
          _b = bets.find(e => {
            return Object.keys(e)[0].toLowerCase() === 'away'
          })
        }
      }

      if (bets.length === 1 && key === 'home') {
        return Object.keys(_b)[0]
      }
      if (bets.length === 1 && key === 'away') {
        return '-'
      }

      const _k = Object.keys(_b)[0]
      return _k
    },

    matchOdds (test, odds, bets) {
      let data = null
      for (const key in odds) {
        data = odds[key][test]
      }
      if (!data) {
        return '-'
      }

      if (bets.length) {
        const _k = bets[0].betArea.split('@')[0]
        data = odds[_k][test]
      }

      return data.V
    }
  },

  mixins: [
    getStyles,
    siteDefaults,
    getListStatus,
    checkAll
  ],

  data () {
    const params = Object.assign({
      rows: 50,
      page: 1,
      filter_by: '',
      q: '',
      sort_by: 'created_at',
      sort: 'desc',
      date_from: '',
      date_to: ''
    }, this.$route.query)

    return {
      translations,
      params,

      selectedCountry: '',
      selectedGameName: '',
      selectedLeague: '',
      selectedRound: '',

      leaguesList: [],
      selected: [],
      selectAll: false,
      autoCompletes: [
        {
          key: 'country',
          data: ['...loading']
        },
        {
          key: 'game_name',
          data: ['...loading']
        },
        {
          key: 'league',
          data: ['...loading']
        }
      ],

      editMatch: {},
      matchList: {},

      status: '',
      filter: '',

      delay: null,

      isDataLoading: true,
      isEmpty: false,
      showDataLoading: false,

      showBetHistory: false,
      showBetModify: false,

      keywords: {}
    }
  },

  computed: {
    ...mapState('bet-history-new', [
      'matches',
      'formatted'
    ]),
    ...mapState('game', { gameTypes: 'types' }),
    ...mapState('config', ['config']),

    language () {
      return this.config.lang || vueLocalStorage.getItem('adminLang')
    },

    isNewGame () {
      return ['token', 'casino'].includes(this.$route.params.game)
    },

    filterBy () {
      const game = this.$route.params.game

      if (game === 'mini') {
        // return [
        //   generate('home_team'),
        //   generate('away_team')
        // ]

        return []
      }

      if (game === 'casino') {
        return [
          generate('name', this.lang === 'en' ? 'name' : 'kor'),
          generate('bet amount', 'total_bet_amount'),
          generate('bet count', 'total')
        ]
      }

      if (game === 'inplay' || game === 'sports') {
        return []
      }

      if (game === 'bet365' || game === 'token') {
        return [
          generate('game', 'name'),
          generate('bet amount', 'total_bet_amount')
        ]
      }

      return [
        generate('home_team'),
        generate('away_team')
      ]
    },

    dateBy () {
      const game = this.$route.params.game

      if (game === 'token' || game === 'casino') {
        return []
      }

      return []
    }
  },

  watch: {
    $route ($router, from) {
      this.showDataLoading = !this.showDataLoading

      let flag = false
      if ($router.query.mgIds) {
        flag = true
      } else {
        this.search(this.generate($router.query), flag)
      }
    },

    showBetModify (val) {
      if (val === false) {
        this.editMatch = {}
      }
    }
  },

  async created () {
    this.params = this.generate(this.params)
    this.params.sort_by = 'created_at'
    this.params.lang = this.language || 'en'
    this.autoCompletes = await this.getAC()
    this.selected = []
    this.search()
    this.getTypes()
  },

  methods: {
    ...mapActions('bet-history-new', {
      getBetHistoryNew: 'get',
      modifyMatchSingle: 'modifyMatchSingle',
      modifyMatchBulk: 'modifyMatchBulk'
    }),

    ...mapActions('game', ['getTypes']),

    ...mapActions('bet-history', [
      'get',
      'restore',
      'cancel',
      'delete',
      'generateAutoComplete',
      'generateLeagues'
    ]),

    checkSameMatches (data, key, index) {
      const matchStatus = this.$refs['match-game-active']
      let selected = {}

      for (const i in matchStatus) {
        if (matchStatus.hasOwnProperty(i)) {
          if (matchStatus[i].$vnode.data.key === key) {
            const match = matchStatus[i]
            selected = Object.assign({}, matchStatus[index].selected)
            match.selected = selected
          }
        }
      }

      for (const e in this.matchList) {
        if (this.matchList.hasOwnProperty(e)) {
          if (this.matchList[e].id === key) {
            this.matchList[e].match_game_active = selected.value
          }
        }
      }
    },

    handleOpenBetHistory ({ game, selected, info }) {
      let mgIds = info.betGroup
      mgIds = Array.from(new Set(mgIds))

      const mgGame = JSON.stringify(game)
      const mgSel = selected

      return this.openNewWindow('/bet-history/details/sports/all', { mgIds, mgGame, mgSel })
    },

    getWinTeam (matchScore, match) {
      matchScore = typeof matchScore === 'string' ? JSON.parse(matchScore) : matchScore
      if (!matchScore) {
        return '-'
      }

      if (matchScore.final && matchScore.final.Home && matchScore.final.Away) {
        if (matchScore.final.Home < matchScore.final.Away) {
          return match.away_team_name
        }
        if (matchScore.final.Away < matchScore.final.Home) {
          return match.home_team_name
        }

        if (matchScore.final.Away === matchScore.final.Home) {
          return 'Draw'
        }
      }
      return '-'
    },

    getTotalBet (match) {
      const total = match.betsRaw.filter(e => {
        return !e.isCancel && !e.isTestUser
      }).map(e => {
        return e.bet_amount
      })

      return total.reduce((e, a) => {
        return parseInt(e) + parseInt(a)
      }, 0)
    },

    getTotalLose (match) {
      const total = match.betsRaw.filter(e => {
        return e.status === 'l' && !e.isCancel
      })

      const totalAll = match.betsRaw.filter(e => {
        return !e.isCancel && !e.isTestUser
      })

      const lose = total.map(e => { return e.bet_amount }).reduce((e, a) => {
        return parseInt(e) + parseInt(a)
      }, 0)
      const tot = totalAll.map(e => { return e.bet_amount }).reduce((e, a) => {
        return parseInt(e) + parseInt(a)
      }, 0)

      return tot - lose
    },

    sort (sortBy) {
      const params = this.params
      params.sort = params.sort === 'asc' ? 'desc' : 'asc'
      params.sort_by = sortBy

      this.$router.push({ query: _pickBy(params, Boolean) })
    },

    getBetArea (bets, key) {
      let _b = null

      if (key === 'home') {
        _b = bets[0]
        if (bets.length === 3) {
          _b = bets.find(e => {
            return Object.keys(e)[0].toLowerCase() === 'home'
          })
        }
      }

      if (key === 'away') {
        _b = bets[1]
        if (bets.length === 3) {
          _b = bets.find(e => {
            return Object.keys(e)[0].toLowerCase() === 'away'
          })
        }
      }

      if (bets.length === 1 && key === 'home') {
        return Object.keys(_b)[0]
      }

      if (bets.length === 1 && key === 'away') {
        return '-'
      }

      return Object.keys(_b)[0]
    },

    checkMidVal (odds, betsRaw) {
      if (betsRaw.length) {
        return betsRaw[0].betArea.split('@')[0]
      }
    },

    open ({ game, selected, info, gameID, prdID, roundID, matchID }) {
      if (gameID) {
        return this.openNewWindow(`/bet-history/details/${game}/all`, {
          ...gameID && { gameID },
          ...roundID && { roundID },
          ...matchID && { matchID }
        })
      }

      if (!info) return

      let mgIds = info.betGroup
      mgIds = Array.from(new Set(mgIds))

      const mgGame = JSON.stringify(game)
      const mgSel = selected

      this.showBetHistory = true
      this.$router.push({ query: { mgIds, mgGame, mgSel } })
    },

    async search (query, flag = false) {
      this.params = query || this.params
      this.selected = []
      this.selectAll = false

      this.selectedCountry = this.keywords.country || null
      this.selectedLeague = this.keywords.league || null
      this.selectedGameName = this.keywords.game || null
      this.selectedRound = this.keywords.round || null

      this.showDataLoading = !flag
      this.isDataLoading = !flag
      this.isEmpty = false

      await this.getBetHistoryNew({
        type: this.$route.params.game,
        status: this.$route.params.status,
        params: {
          country: this.selectedCountry,
          game: this.selectedGameName,
          league: this.selectedLeague,
          round: this.selectedRound,
          query: this.params
        }
      })
      this.matchList = Object.assign({}, this.formatted)

      this.isDataLoading = false
      this.showDataLoading = false
      this.isEmpty = _isEmpty(this.matchList)
    },

    clear () {
      this.keywords = []

      this.selectedCountry = ''
      this.selectedGameName = ''
      this.selectedLeague = ''
      this.selectedRound = ''

      this.$router.push({ query: {} })

      // this.$refs.autocompleteCountry.search = ''
      // this.$refs.autocompleteLeague.search = ''
      // this.$refs.autocompleteGame.search = ''
    },

    async getAC () {
      this.generateLeagues({
        country: this.selectedCountry || '',
        game: this.selectedGameName || ''
      }).then(r => {
        if (r.length) {
          this.leaguesList = r
        }
      })

      const data = await this.generateAutoComplete()
      return data
    },

    trigger (q) {
      if (_isEqual(q, this.params)) {
        return
      }

      let params = this.params
      params = q || params
      params.country = this.selectedCountry || ''
      params.game = this.selectedGameName || ''
      params.league = this.selectedLeague || ''
      params.lang = this.language || 'en'

      this.$router.push({ query: _pickBy(params, Boolean) })
    },

    showModify (match) {
      this.showBetModify = true
      this.editMatch = Object.assign({}, match)
    },

    modifyMatch (type) {
      const success = async () => {
        if (type === 'single') {
          const params = this.editMatch
          await this.modifyMatchSingle({ params })
        } else if (type === 'bulk') {
          const selected = this.selected
          const data = this.matchList
          const params = []

          for (const i in data) {
            if (selected.indexOf(data[i].id) !== -1) {
              params.push({
                id: data[i].id,
                status: data[i].match_game_active
              })
            }
          }

          await this.modifyMatchBulk({ params })
        }

        this.showBetModify = false
        this.search()
      }

      const swalMsg = ''
      this.$alert('prompt', swalMsg, {}, { success }, 'modify')
    },

    async filterByKeywords (data) {
      this.keywords = data
      await this.search()
    }
  }
}
</script>

<style lang="scss" scoped>
.capitalize {
  text-transform: capitalize;
}
</style>
